<template>
    <ve-table
        class="zones-monitoring-table"
        :columns="columns"
        :table-data="tableData"
        :border-y="true"
        :style="`width: ${size.width}px`"
        :scroll-width="size.width - zoneWidth - restaurantNameWidth"
        :max-height="size.height"
        :fixex-header="true"
        id="zones-loading-container" />
</template>

<script>
import moment from "moment";

const zoneKey = "zone";
const restaurantKey = "restaurant";
const zoneWidth = 260;
const restaurantNameWidth = 300;
const fieldSeparator = ":";

export default {
    name: "ZonesMonitoringTable",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: () => ({
                width: 0,
                height: 0,
            }),
        },
        data: {
            type: Object,
            default: () => ({
                mealTypes: {},
                restaurant: {},
                zones: {},
            }),
        },
    },
    data: () => ({
        columns: [],
        tableData: [],
        zoneWidth,
        restaurantNameWidth,
        loadingInstance: null,
    }),
    watch: {
        data() {
            this.generateTabelData();
        },
        loading(isLoading) {
            if (isLoading) {
                this.loadingInstance.show();
            } else {
                this.loadingInstance.close();
            }
        },
    },
    methods: {
        generateTabelData() {
            this.generateColumns();
            this.generateTabelRows();
        },
        generateColumns() {
            const columns = [
                {
                    field: zoneKey,
                    key: zoneKey,
                    title: "Территория",
                    width: zoneWidth,
                },
                {
                    field: restaurantKey,
                    key: restaurantKey,
                    title: "Название ресторана",
                    renderBodyCell: this.renderRestBodyCell,
                    width: restaurantNameWidth,
                },
            ];
            const mealLunch = {
                field: "lunch",
                key: "lunch",
                title: "Ланчи",
                width: 56,
            };

            // формируем колонки под датами
            const mealTypes = this.data.mealTypes;
            const mealTypesKeys = Object.keys(mealTypes);
            const mealTypesColumns = mealTypesKeys.map(mealKey => ({
                field:      mealKey,
                key:        mealKey,
                title:      mealKey,
                plan:       mealTypes[mealKey].plan,
                lunchPlan:  mealTypes[mealKey].lunchPlan,
                width:      56,
            }));

            // ланчи всегда первой колонкой
            mealTypesColumns.unshift(mealLunch);

            // доставем все даты всех ресторанов
            const dates = {};
            const restaurants = this.data.restaurant;
            Object.values(restaurants).forEach(({ report }) => {
                Object.keys(report).forEach((date) => (dates[date] = true));
            });

            // формируем колонки дат с подколонками
            const datesKeys = Object.keys(dates).sort((d1, d2) => (new Date(d1) > new Date(d2) ? 1 : -1));
            datesKeys.forEach((date) => {
                const subColumns = mealTypesColumns.map((el) => {
                    const key = date + fieldSeparator + el.key;

                    return {
                        ...el,
                        field: key,
                        key,
                        renderHeaderCell: this.renderMealHeaderCell,
                        renderBodyCell: this.renderMealBodyCell,
                    };
                });

                columns.push({
                    title: moment(date).format("DD MMMM YYYY"),
                    children: subColumns,
                    renderHeaderCell: ({ column }) => {
                        return <div class="th-date">{column.title}</div>
                    }
                });
            });

            this.columns = columns;
        },
        generateTabelRows() {
            const tableData = [];

            const zones = this.data.zones;
            const zonesKeys = Object.keys(zones).sort((zoneKeyA, zoneKeyB) => {
                const zoneA = zones[zoneKeyA]
                const zoneB = zones[zoneKeyB]
                
                return zoneA.name.toLowerCase() > zoneB.name.toLowerCase() ? 1 : -1
            });
            const restaurants = this.data.restaurant;

            zonesKeys.forEach((key) => {
                const rowData = {};
                const zone = zones[key];
                const zoneRestaurants = zone.restaurants.map((restId) => restaurants[restId]);

                this.columns.forEach((colData) => {
                    if (colData.key === zoneKey) {
                        rowData[zoneKey] = zone.name;
                        return;
                    }

                    if (colData.key === restaurantKey) {
                        rowData[restaurantKey] = "";
                        rowData.restaurants = zoneRestaurants;
                        return;
                    }

                    colData.children.forEach(({ field, key: childKey }, idx) => {
                        rowData[childKey] = idx;
                    });
                });

                tableData.push(rowData);
            });

            this.tableData = tableData;

            console.log(this.tableData)
        },

        renderRestBodyCell({ row }) {
            const rowRests = row.restaurants;

            return (
                <div>
                    {rowRests.map(({ name, active }) => {
                        const classes = ["rest"];
                        !active && classes.push("not-active");

                        return <div class={classes}>{name}</div>;
                    })}
                </div>
            );
        },
        renderMealHeaderCell({ column }) {
            const [, field] = column.field.split(fieldSeparator);
            const plan = `${ column.plan ? column.plan : 0 }/${ column.lunchPlan ? column.lunchPlan : 0 }`

            switch (field) {
                case "lunch":
                    return <div class='th-meal-box'><div class="th-meal-name">Ланчи</div><span class="th-plan"></span></div>
                case "second":
                    return <div class='th-meal-box'><div class="th-meal-name">Второе</div><span class="th-plan">{plan}</span></div>
                case "dinner":
                    return <div class='th-meal-box'><div class="th-meal-name">Ужин</div><span class="th-plan">{plan}</span></div>
                case "Zakuski":
                    return <div class='th-meal-box'><div class="th-meal-name">Закуски</div><span class="th-plan">{plan}</span></div>
                case "Shaurma":
                    return <div class='th-meal-box'><div class="th-meal-name">Шаурма</div><span class="th-plan">{plan}</span></div>
                case "Vok":
                    return <div class='th-meal-box'><div class="th-meal-name">Вок</div><span class="th-plan">{plan}</span></div>
                default:
                    return <div class='th-meal-box'><div class={["th-icon", field]} /><span class="th-plan">{plan}</span></div>;
            } 
        },
        renderMealBodyCell({ row, column }) {
            const rows = [];
            const colKey = column.field;
            const [date, key] = colKey.split(fieldSeparator);
            const restaurants = row.restaurants;

            restaurants.forEach((rest) => {
                const active = rest.active;
                const activeClass = active ? "active" : "not-active";

                const restDateReport = rest.report[date];

                if (key === "lunch") {
                    const status = restDateReport?.status || "";

                    rows.push(
                        <div class={["meal-row", activeClass]}>
                            <div class={["lunch", status]} />
                        </div>
                    );
                    return;
                }

                const restReportTypes = restDateReport?.types;

                if (!restReportTypes || !restReportTypes[key]) {
                    rows.push(<div class={["meal-row", "missing", activeClass]}>0</div>);
                    return;
                }

                const { constructMealsCount, mealsCount, status } = restReportTypes[key];

                const text = constructMealsCount === 0 ? mealsCount : `${mealsCount}/${constructMealsCount}`;

                rows.push(<div class={["meal-row", status, activeClass]}>{text}</div>);
            });

            if (!rows.length) {
                return <div>0</div>;
            }

            return <div class="meal">{rows}</div>;
        },
    },
    mounted() {
        this.generateTabelData();

        this.loadingInstance = this.$veLoading({
            target: "#zones-loading-container",
            name: "flow",
            color: "#40AE49",
            height: 80,
            width: 80,
        });
    },
    destroyed() {
        this.loadingInstance.destroy();
    },
};
</script>

<style lang="scss">
.zones-monitoring-table {
    font-family: $mainFontNew;

    thead {
        th {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    tbody {
        td {
            padding: 0 !important;
        }
    }

    .th-date {
        padding: 0 4px;
    }

    .th-plan {
        position: relative;
        display: block;
        height: 20px;
        font-size: 12px;
    }

    .th-meal-name {
        display: grid;
        place-items: center;
        height: 32px;
    }

    .rest {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eee;
        padding: 0 12px;

        &:last-child {
            border-width: 0;
        }
    }

    .meal {
    }

    .th-icon {
        width: 32px;
        height: 32px;
        background-color: #40ae49;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: cover;
        margin: 0 auto;

        &.salat {
            mask-image: url(~@/assets/img/salat.svg);
        }
        &.sushi {
            mask-image: url(~@/assets/img/sushi.svg);
        }
        &.sup {
            mask-image: url(~@/assets/img/sup.svg);
        }
        &.hot {
            mask-image: url(~@/assets/img/hot.svg);
        }
        &.garnir {
            mask-image: url(~@/assets/img/garnir.svg);
        }
        &.sauce {
            mask-image: url(~@/assets/img/sous.svg);
        }
        &.juse {
            mask-image: url(~@/assets/img/drink.svg);
        }
        &.kebabs {
            mask-image: url(~@/assets/img/kebabs.svg);
        }
        &.burgers {
            mask-image: url(~@/assets/img/burgers.svg);
        }
        &.pizza {
            mask-image: url(~@/assets/img/pizza.svg);
        }
        &.khinkali {
            mask-image: url(~@/assets/img/khinkali.svg);
        }
        &.vipechka {
            mask-image: url(~@/assets/img/vipechka.svg);
        }
        &.poke {
            mask-image: url(~@/assets/img/poke.svg);
        }
        &.roll {
            mask-image: url(~@/assets/img/roll.svg);
        }
        &.second {
            // mask-image: url(~@/assets/img/second.svg); // не добавлена
        }
        &.desert {
            mask-image: url(~@/assets/img/desert.svg);
        }
        &.Hinkali {
            mask-image: url(~@/assets/img/khinkali.svg);
        }
        &.Pizza {
            mask-image: url(~@/assets/img/pizza.svg);
        }
        &.Burgeri {
            mask-image: url(~@/assets/img/burgers.svg);
        }
        &.Shashliki {
            mask-image: url(~@/assets/img/Shashliki.svg);
        }
        &.breakfast {
            mask-image: url(~@/assets/img/breakfast.svg);
        }
    }

    .meal-row {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eee;

        &:last-child {
            border-width: 0;
        }

        .lunch {
            width: 100%;
            height: 100%;
            mask-image: url("~@/assets/img/confirm.svg");
            background-color: $green;
            mask-repeat: no-repeat;
            mask-position: center;

            &.partial { background-color: $red }
            &.noPlan { background-color: $black }
        }
    }

    .not-active {
        background-color: $pink;
    }

    .done {
        color: $green;
    }

    .partial {
        color: $yelow;
    }

    .missing {
        color: $red;
    }
}
</style>
