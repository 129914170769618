<template>
	<div
		class="city-select"
		:class="{'city-select--active': isActive, 'city-select--popup': isPopupHeader, 'city-fullscreen': this.$route.path === '/'}"
	>
		<div class="city-select__top">
			<div class="city-select__top-choose">
				<ArrowBackIcon
					@click="closeCitySelect"
					style="cursor: pointer"
				/>
				<span>Выберите города</span>
			</div>
		</div>
		<ul class="city-select__list">
			<li
				class="city-select__list-item"
				v-for="city in cities"
				:key="city.UF_NAME"
				@click="handleSelectCity($event.target, city)"
			>{{city.UF_NAME}}</li>
		</ul>
    <button class="city-select__apply" @click="citiesListApply()">Применить</button>
	</div>
</template>

<script>
import ArrowBackIcon from "icons/24/arrow-back.svg?inline";
import { mapGetters } from "vuex";

export default {
	name: "CitySelect",
	props: {
		isActive: {
			type: Boolean,
			default: false
		},
		isPopupHeader: {
			type: Boolean,
			default: false
		}
	},
	components: { ArrowBackIcon },
	data()
	{
		return {
      pickedCities: [],
			currentCity: '',
		}
	},
	computed:
		{
			...mapGetters({
				cities: "getCities",
				getCurrentCity: "getCurrentCity",
				currentCityId: "currentCityId",
			}),
		},
	methods:
		{
			handleSelectCity(elem, city) {
        elem.classList.contains('city-select__list-item--selected') ? 
          this.pickedCities.splice(this.pickedCities.indexOf(city.UF_XML_ID), 1) : 
          this.pickedCities.push(city.UF_XML_ID)
        elem.classList.toggle('city-select__list-item--selected')
      },

			clearCity() { this.currentCity = '' },

			closeCitySelect() { this.$emit("closeCitySelect", false) },

      citiesListApply() {
        if (this.pickedCities.length > 0)
          this.$emit('applyCities', this.pickedCities)
        this.closeCitySelect()
      }
		},
	mounted()
	{
		this.currentCity = this.getCurrentCity || '';
	}
};
</script>

<style lang="scss">
.city-select
{
	position: fixed;
	top: 0;
	left: -100%;
	width: 100%;
	z-index: 10;
	height: calc(100vh - 64px);
	overflow-y: auto;
	padding: 18px 32px;
	background: #fff;
	transition: left 0.3s ease;

	&--active
	{left: 0;}

	.city-select__top-choose
	{
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&--popup
	{
		position: static;
		min-width: 400px;
		width: 100%;
		height: auto;
		overflow-y: auto;

		.city-select__top-close
		{display: none;}

		&::v-deep
		{
			.popup-close
			{
				top: 16px;
				right: 16px;
			}
		}
	}
}

.city-fullscreen
{
	height: 100vh;
}

.city-select__top
{margin-bottom: 8px;}

.city-select__top-choose
{
	margin-bottom: 24px;

	span
	{
		font-family: $mainFontNew;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 32px;
		color: #3D4248;
	}

	img
	{margin-right: 12px;}
}

.city-select__top-label
{
	position: relative;
	display: block;

	span
	{
		position: absolute;
		left: 16px;
		bottom: 34px;
		z-index: 1;
		font-family: $mainFontNew;
		font-style: normal;
		font-weight: 400;
		font-size: 11px;
		line-height: 14px;
		color: #979797;
	}

	img
	{
		position: absolute;
		right: 22px;
		top: 50%;
		transform: translate(0, -50%);
		cursor: pointer;
	}
}

.city-select__list-item
{
	padding: 16px 0;
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #3D4248;
	cursor: pointer;

  &:not(&:last-child) {
    border-bottom: 1px solid #E5E5E7;
  }

	&--selected
	{
		color: #40AE49;
		display: flex;
		justify-content: space-between;

		&::after
		{
			content: '';
			background-image: url('~@/assets/img/icons/choose-icon.svg');
			background-repeat: no-repeat;
			background-size: contain;
			width: 24px;
			height: 24px;
			display: inline-block;
			right: 0;
		}
	}

	.cities-enter-active, .cities-leave-active
	{transition: all .4s;}

	.cities-enter, .cities-leave-to
	{
		transform: translateX(100px);
		opacity: 0;
	}
}

.city-select__apply {
  width: 100%;
  height: 45px;
  border: none;
  background-color: green;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  color: white;
}
</style>