<template>
    <ve-table
        class="rests-monitoring-table"
        :columns="columns"
        :table-data="tableData"
        :border-y="true"
        :style="`width: ${size.width}px`"
        :scroll-width="size.width - restaurantNameWidth"
        :max-height="size.height"
        :fixex-header="true"
        :cell-style-option="cellStyleOption"
        id="rests-loading-container" />
</template>

<script>
import moment from "moment";

const restaurantKey = "restaurant";
const restaurantNameWidth = 350;
const fieldSeparator = ":";
const mealLunch = {
    field: "lunch",
    key: "lunch",
    title: "Ланчи",
    width: 56,
};

export default {
    name: "RestsMonitoringTable",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: () => ({
                width: 0,
                height: 0,
            }),
        },
        data: {
            type: Object,
            default: () => ({
                mealTypes: {},
                restaurant: {},
                zones: {},
            }),
        },
    },
    data: () => ({
        columns: [],
        tableData: [],
        restaurantNameWidth,
        loadingInstance: null,
        cellStyleOption: {
            bodyCellClass: ({ row, column, rowIndex }) => {
                if (!row.isActive) {
                    return 'not-active'
                }
            },
        },
    }),
    watch: {
        data() {
            this.generateTabelData();
        },
        loading(isLoading) {
            if (isLoading) {
                this.loadingInstance.show();
            } else {
                this.loadingInstance.close();
            }
        },
    },
    methods: {
        generateTabelData() {
            this.generateColumns();
            this.generateTabelRows();
        },
        generateColumns() {
            const columns = [
                {
                    field: restaurantKey,
                    key: restaurantKey,
                    title: "Название ресторана",
                    // renderBodyCell: this.renderRestBodyCell,
                    fixed: "left",
                    width: restaurantNameWidth,
                },
            ];

            // формируем колонки под датами
            const mealTypes = this.data.mealTypes;
            const mealTypesKeys = Object.keys(mealTypes);
            const mealTypesColumns = mealTypesKeys.map(mealKey => ({
                field:      mealKey,
                key:        mealKey,
                title:      mealKey,
                plan:       mealTypes[mealKey].plan,
                lunchPlan:  mealTypes[mealKey].lunchPlan,
                width:      66,
            }));

            // ланчи всегда первой колонкой
            mealTypesColumns.unshift(mealLunch);

            // доставем все даты всех ресторанов
            const dates = {};
            const restaurants = this.data.restaurant;
            Object.values(restaurants).forEach(({ report }) => {
                Object.keys(report).forEach((date) => (dates[date] = true));
            });

            // формируем колонки дат с подколонками
            const datesKeys = Object.keys(dates).sort((d1, d2) => (new Date(d1) > new Date(d2) ? 1 : -1));
            datesKeys.forEach((date) => {
                const subColumns = mealTypesColumns.map((el) => {
                    const key = date + fieldSeparator + el.key;

                    return {
                        ...el,
                        field: key,
                        key,
                        renderHeaderCell: this.renderMealHeaderCell,
                        // renderBodyCell: this.renderMealBodyCell,
                    };
                });

                columns.push({
                    title: moment(date).format("DD MMMM YYYY"),
                    children: subColumns,
                    renderHeaderCell: ({ column }) => {
                        return <div class="th-date">{column.title}</div>
                    }
                });
            });

            this.columns = columns;
        },
        generateTabelRows() {
            const tableData = [];
            const restaurants = Object.values(this.data.restaurant);

            restaurants
                .forEach((rest) => {
                    const rowData = {};
                    rowData.isActive = rest.active

                    this.columns.forEach((colData) => {
                        if (colData.key === restaurantKey) {
                            rowData[restaurantKey] = (
                                <div class="rest">
                                    <div class="rest-title">{rest.name}</div>
                                    <div class="rest-address">{rest.address}</div>
                                </div>
                            );
                            return
                        }

                        colData.children.forEach(({ field, key: childKey }, idx) => {
                            const [date, mealKey] = field.split(fieldSeparator)
                            const mealDayData = rest.report[date]

                            if (mealKey === mealLunch.field) {
                                const status = mealDayData?.status || ''
                                rowData[childKey] = <div class={["lunch", status]} />;
                                return
                            }

                            const mealData = mealDayData?.types[mealKey]

                            
                            if (!mealDayData || !mealData) {
                                rowData[childKey] = <span class="missing">0</span>;
                                return
                            }

                            const { constructMealsCount, mealsCount, status } = mealData;
                            const text = constructMealsCount === 0 ? mealsCount : `${mealsCount}/${constructMealsCount}`;

                            rowData[childKey] = <span class={status}>{text}</span>;
                        });
                    });

                    tableData.push(rowData);
                });

            this.tableData = tableData;
        },

        renderMealHeaderCell({ column }) {
            const [, field] = column.field.split(fieldSeparator);
            const plan = `${ column.plan ? column.plan : 0 }/${ column.lunchPlan ? column.lunchPlan : 0 }`

            switch (field) {
                case "lunch":
                    return <div class='th-meal-box'><div class="th-meal-name">Ланчи</div><span class="th-plan"></span></div>
                case "second":
                    return <div class='th-meal-box'><div class="th-meal-name">Второе</div><span class="th-plan">{plan}</span></div>
                case "dinner":
                    return <div class='th-meal-box'><div class="th-meal-name">Ужин</div><span class="th-plan">{plan}</span></div>
                case "Zakuski":
                    return <div class='th-meal-box'><div class="th-meal-name">Закуски</div><span class="th-plan">{plan}</span></div>
                case "Shaurma":
                    return <div class='th-meal-box'><div class="th-meal-name">Шаурма</div><span class="th-plan">{plan}</span></div>
                case "Vok":
                    return <div class='th-meal-box'><div class="th-meal-name">Вок</div><span class="th-plan">{plan}</span></div>
                default:
                    return <div class='th-meal-box'><div class={["th-icon", field]} /><span class="th-plan">{plan}</span></div>;
            } 
        },
    },
    mounted() {
        this.generateTabelData();

        this.loadingInstance = this.$veLoading({
            target: "#rests-loading-container",
            name: "flow",
            color: "#40AE49",
            height: 80,
            width: 80,
        });
    },
};
</script>

<style lang="scss">
.rests-monitoring-table {
    font-family: $mainFontNew;

    thead {
        th {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    tbody {
        td {
            padding: 0 !important;
        }
    }

    .th-date {
        padding: 0 4px;
    }

    .th-plan {
        position: relative;
        display: block;
        height: 20px;
        font-size: 12px;
    }

    .th-meal-name {
        display: grid;
        place-items: center;
        height: 32px;
    }

    .th-icon {
        width: 32px;
        height: 32px;
        background-color: #40ae49;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: cover;
        margin: 0 auto;

        &.salat {
            mask-image: url(~@/assets/img/salat.svg);
        }
        &.sushi {
            mask-image: url(~@/assets/img/sushi.svg);
        }
        &.sup {
            mask-image: url(~@/assets/img/sup.svg);
        }
        &.hot {
            mask-image: url(~@/assets/img/hot.svg);
        }
        &.garnir {
            mask-image: url(~@/assets/img/garnir.svg);
        }
        &.sauce {
            mask-image: url(~@/assets/img/sous.svg);
        }
        &.juse {
            mask-image: url(~@/assets/img/drink.svg);
        }
        &.kebabs {
            mask-image: url(~@/assets/img/kebabs.svg);
        }
        &.burgers {
            mask-image: url(~@/assets/img/burgers.svg);
        }
        &.pizza {
            mask-image: url(~@/assets/img/pizza.svg);
        }
        &.khinkali {
            mask-image: url(~@/assets/img/khinkali.svg);
        }
        &.vipechka {
            mask-image: url(~@/assets/img/vipechka.svg);
        }
        &.poke {
            mask-image: url(~@/assets/img/poke.svg);
        }
        &.roll {
            mask-image: url(~@/assets/img/roll.svg);
        }
        &.second {
            // mask-image: url(~@/assets/img/second.svg); // не добавлена
        }
        &.desert {
            mask-image: url(~@/assets/img/desert.svg);
        }
        &.Hinkali {
            mask-image: url(~@/assets/img/khinkali.svg);
        }
        &.Pizza {
            mask-image: url(~@/assets/img/pizza.svg);
        }
        &.Burgeri {
            mask-image: url(~@/assets/img/burgers.svg);
        }
        &.Shashliki {
            mask-image: url(~@/assets/img/Shashliki.svg);
        }
        &.breakfast {
            mask-image: url(~@/assets/img/breakfast.svg);
        }
    }

    .rest {
        padding: 10px 20px;
    }
    
    .rest-title {
        text-align: left;
        font-family: $mainFontMediumNew;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 2px;
    }

    .rest-address {
        text-align: left;
        font-size: 12px;
        line-height: 16px;
    }

    .lunch {
        width: 100%;
        height: 100%;
        mask-image: url("~@/assets/img/confirm.svg");
        background-color: $green;
        mask-repeat: no-repeat;
        mask-position: center;

        &.partial { background-color: $red }
        &.noPlan { background-color: $black }
    }

    .not-active {
        background-color: $pink !important;
    }

    .done {
        color: $green;
    }

    .partial {
        color: $yelow;
    }

    .missing {
        color: $red;
    }
}
</style>
