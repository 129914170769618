    <template>
    <div :class="$style.container" ref="container">
        <NavTabs v-if="isManager" :data="filtersByConnect" active="Все" @changeActive="changeActiveTab" />

        <div :class="$style.filters">
            <div :class="$style.switch" v-if="isManager">
                <button
                    v-for="{ name, value } in filtersByPlace"
                    :key="value"
                    @click="filterByPlace = value"
                    :class="[filterByPlace === value && $style.active]">
                    {{ name }}
                </button>
            </div>

            <div :class="$style.dates">
                <label for="minDate" :class="$style.datesLabel">
                    <img src="@/assets/icons/calendar.svg" alt="" />
                    <input type="text" id="minDate" placeholder="выберите дату" readonly />
                    <img src="@/assets/icons/arr-down.svg" alt="" />
                </label>

                -

                <label for="maxDate" :class="$style.datesLabel">
                    <img src="@/assets/icons/calendar.svg" alt="" />
                    <input type="text" id="maxDate" placeholder="выберите дату" readonly />
                    <img src="@/assets/icons/arr-down.svg" alt="" />
                </label>
            </div>

			<div :class="$style.citiesPopup" @click="isPopupHeader = !isPopupHeader;">
				<span class="header-main__nav-item">Выбрать город</span>
			</div>

            <div :class="$style.search">
                <img src="@/assets/icons/glass.svg" alt="" />
                <input type="text" placeholder="Поиск ресторана, территориии" v-model="search" />
                <button v-if="search" @click="cleanSearсh()">
                    <img src="@/assets/icons/cross.svg" alt="" />
                </button>
            </div>
        </div>

        <div :class="$style.tableWrap" ref="tableWrap">
            <ZonesMonitoringTable
                v-if="tableData && tableSize && filterByPlace === 'zones'"
                :data="tableData"
                :size="tableSize"
                :loading="isTableDataLoading" />
            <RestsMonitoringTable
                v-if="tableData && tableSize && filterByPlace === 'rests'"
                :data="tableData"
                :size="tableSize"
                :loading="isTableDataLoading" />
        </div>
		<Popup :visible.sync="isPopupHeader">
			<CitySelect
				:isPopupHeader="isPopupHeader"
				@closeCitySelect="closeCitySelect"
				@applyCities="applyCities"
			/>
		</Popup>
    </div>
</template>

<script>
import NavTabs from "@/components/block/NavTabs";
import ZonesMonitoringTable from "@/components/ui/ZonesMonitoringTable";
import RestsMonitoringTable from "@/components/ui/RestsMonitoringTable";
import { mapActions, mapGetters } from "vuex";
import CitySelect from "@/components/popup/CitiesSelect";
import AirDatepicker from "air-datepicker";
import moment from "moment";
import debounce from "lodash/debounce";
import axios from 'axios';
import qs from 'qs'


import "air-datepicker/air-datepicker.css";
import "@/assets/scss/datepicker.scss";

const maxDiffDates = 13;

moment.locale("ru");
const prevDate  = moment().format('YYYY-MM-DD');
const nowDate   = moment().add(1, 'days').format('YYYY-MM-DD');

const getAnyDate = (date, count = 1) => {
    const d = new Date(date);
    const maxDate = d.setDate(d.getDate() + count);

    return moment(maxDate).format("YYYY-MM-DD")
}

const getDiffDaysBetweenDates = (startDate, endDate) => {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);
    const differenceInTime = d2.getTime() - d1.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.abs(differenceInDays);
}


const connectFiltertTabs = {
    Все: {
        name: "Все",
        target: undefined,
    },
    Подключенные: {
        name: "Подключенные",
        target: true,
    },
    "Не подключенные": {
        name: "Не подключенные",
        target: false,
    },
};

export default {
    name: "DishesMonitoring",
    components: { NavTabs, ZonesMonitoringTable, RestsMonitoringTable, CitySelect },
    data: () => ({
        activeTab: Object.values(connectFiltertTabs)[0].target,
        filterByPlace: "rests",
		isPopupHeader: false,
        filtersByPlace: [
            {
                name: "Территория",
                value: "zones",
            },
            {
                name: "Рестораны",
                value: "rests",
            },
        ],
        filtersByConnect: Object.values(connectFiltertTabs),
        minDate: prevDate,
        minDatepicker: undefined,
        maxDate: nowDate,
        maxDatepicker: undefined,
        search: "",
        tableData: undefined,
        isTableDataLoading: false,
        tableSize: undefined,
        isManager: false
    }),
	computed: {
		...mapGetters({
			currentCity: "getCurrentCity",
			cities: "getCities"
		}),
	},
    watch: {
        minDate() {
            this.fetchData();
        },
        maxDate() {
            this.fetchData();
        },
        search() {
            this.fetchData();
        },
        activeTab() {
            this.fetchData();
        },
    },
    methods: {
		...mapActions({
			getCurrentCity: "getCurrentCity",
			changeCity: "changeCity",
			getCityList: "getCityList",
		}),
		togglePopupHeader()
		{
			
		},
		closeCitySelect(value)
		{
			this.isPopupHeader = value;
		},
		async applyCities(citiesList) {
			const data = new FormData();
			data.append("dateFrom", this.minDate);
    		data.append("dateTo", this.maxDate);
    		data.append("search", this.search);
    		data.append("search", this.search);
			citiesList.forEach(el => data.append("cityXmlIds[]", el))

			try {
    		    const response = await this.$axios.post(
    		        "/restaurant/getAddedMealsMonitor",
    		        data,
    		        {
    		            headers: {
    		                'Content-Type': 'multipart/form-data'
    		            }
    		        }
    		    );
				
    		    this.tableData = response.data;
    		} catch (e) {
    		    console.log('fetch monitoring data exception:', e);
    		} finally {
    		    this.isTableDataLoading = false;
    		}
		},
        fetchData: debounce(async function () {
            const qs = require("qs");
            this.isTableDataLoading = true;

            try {
                const response = await this.$axios.post(
                    "/restaurant/getAddedMealsMonitor",
                    qs.stringify({
                        dateFrom: this.minDate,
                        dateTo: this.maxDate,
                        zones: true,
                        search: this.search,
                        ...(this.activeTab !== undefined && { connected: this.activeTab })
                    })
                );

                this.tableData = response.data;
            } catch (e) {
                console.log('fetch monitoring data exeption:', e)
            } finally {
                this.isTableDataLoading = false;
            }
        }, 300),
        changeActiveTab(filter) {
            this.activeTab = filter;
        },
        cleanSearсh() {
            this.search = "";
        },
        initMinDatepicker() {
            this.minDatepicker = new AirDatepicker("#minDate", {
                inline: false,
                autoClose: true,
                dateFormat: "dd.MM.yyyy",
                selectedDates: [this.minDate],
                startDate: this.minDate,
                onSelect: (date) => {
                    const currentDate = date.formattedDate.split(".").reverse().join("-");

                    if (new Date(currentDate) > new Date(this.maxDate)) {
                        const firmatedMaxDate = getAnyDate(currentDate, 1)
                        this.maxDate = firmatedMaxDate;
                        this.maxDatepicker.selectDate(firmatedMaxDate);
                        this.minDate = currentDate;
                        return
                    }
                    
                    const diffDays = getDiffDaysBetweenDates(currentDate, this.maxDate);

                    if (diffDays > maxDiffDates) {
                        const firmatedMaxDate = getAnyDate(currentDate, maxDiffDates)
                        this.maxDate = firmatedMaxDate;
                        this.maxDatepicker.selectDate(firmatedMaxDate);
                        this.minDate = currentDate;
                        return
                    }

                    this.minDate = currentDate;
                },
            });
        },
        initMaxDatepicker() {
            this.maxDatepicker = new AirDatepicker("#maxDate", {
                inline: false,
                autoClose: true,
                dateFormat: "dd.MM.yyyy",
                selectedDates: [this.maxDate],
                startDate: this.maxDate,
                onSelect: (date) => {
                    const currentDate = date.formattedDate.split(".").reverse().join("-");

                    if (new Date(currentDate) < new Date(this.minDate)) {
                        const firmatedMinDate = getAnyDate(currentDate, -1)
                        this.minDate = firmatedMinDate;
                        this.minDatepicker.selectDate(firmatedMinDate);
                        this.maxDate = currentDate;
                        return
                    }
                    
                    const diffDays = getDiffDaysBetweenDates(currentDate, this.minDate);

                    if (diffDays > maxDiffDates) {
                        const firmatedMinDate = getAnyDate(currentDate, -maxDiffDates)
                        this.minDate = firmatedMinDate;
                        this.minDatepicker.selectDate(firmatedMinDate);
                        this.maxDate = currentDate;
                        return
                    }

                    this.maxDate = currentDate;
                },
            });
        },
        async getRestType() {
            try {
                const { data } 	= await axios.post('/auth/isLogged/');
                if (data.user.GROUPS.indexOf('1') >= 0)
                    this.isManager = true
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.getRestType();
        this.initMinDatepicker();
        this.initMaxDatepicker();
        this.fetchData();

        this.tableSize = {
            width: this.$refs.tableWrap.clientWidth,
            height: this.$refs.tableWrap.clientHeight,
        }

		this.getCityList();
    },
};
</script>

<style lang="scss">
.ve-table-body::-webkit-scrollbar {
    height: 10px;
}
</style>

<style lang="scss" module>
.tableWrap {
    max-width: 100%;
    overflow: hidden;
    flex: 1;
}

.container {
    padding: 40px 72px;
    display: flex;
    flex-direction: column;
}

.filters {
    display: flex;
    height: 48px;
    column-gap: 24px;
    margin-bottom: 16px;
}

.switch {
    padding: 4px;
    background: $greyBackground;
    border-radius: 8px;
    min-width: 316px;

    button {
        font-family: $mainFontBoldNew;
        height: 100%;
        background: inherit;
        border: none;
        color: $textColor;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        border-radius: 8px;
        padding: 0 32px;
        transition: all 0.2s ease;

        &.active {
            background: white;
            color: $green;
            box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
        }
    }
}

.dates {
    display: flex;
    align-items: center;
    column-gap: 12px;

    input {
        cursor: pointer;
    }
}

.datesLabel {
    display: flex;
    align-items: center;
    border: 1px solid $greyDelimiter;
    border-radius: 8px;
    padding: 0 12px;
    cursor: pointer;

    input {
        height: 48px;
        border-width: 0;
        margin-left: 12px;
        margin-right: 16px;
        font-family: $mainFontNew;
        font-size: 14px;
        line-height: 16px;
        max-width: 80px;
    }
}

.search {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 12px;
    border: 1px solid $greyDelimiter;
    border-radius: 8px;
    padding: 0 12px;

    input {
        flex: 1;
        font-family: $mainFontNew;
        font-size: 14px;
        line-height: 16px;
        height: 100%;
        border-width: 0;
    }

    button {
        border: none;
        padding: 0;
        background-color: transparent;

        img {
            display: block;
        }
    }
}

.citiesPopup {
	width: 150px;
	display: grid;
	place-items: center;
	cursor: pointer;
	border: 1px solid $greyDelimiter;
    border-radius: 8px;
	font-size: 14px;
}
</style>
